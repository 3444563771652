export const setHasChildField = (profileKeysChecks, step1State, key) => {
  if (step1State.is_profile_changed === 1) {
    profileKeysChecks['is_profile_changed'] = {
      ...profileKeysChecks['is_profile_changed'],
      hasChild: true,
    };
  }
  if (step1State.is_profile_changed === 0) {
    profileKeysChecks['is_profile_changed'] = {
      ...profileKeysChecks['is_profile_changed'],
      hasChild: false,
    };
  }
  if (step1State.is_pharmacy_address_changed === 1) {
    profileKeysChecks['is_pharmacy_address_changed'] = {
      ...profileKeysChecks['is_pharmacy_address_changed'],
      hasChild: true,
    };
  }
  if (step1State.is_pharmacy_address_changed === 0) {
    profileKeysChecks['is_pharmacy_address_changed'] = {
      ...profileKeysChecks['is_pharmacy_address_changed'],
      hasChild: false,
    };
  }
  if (
    step1State.phone_emergency_contact_relationship.toLowerCase() === 'other'
  ) {
    profileKeysChecks['phone_emergency_contact_relationship'] = {
      ...profileKeysChecks['phone_emergency_contact_relationship'],
      hasChild: true,
    };
  }
  return profileKeysChecks, step1State;
};
