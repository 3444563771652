import { get, post, put } from './api';
import * as actionTypes from './constant/actionTypes';
import * as EndPoint from './constant/EndPoint';
import { store } from '../../stores/configureStore';
import { profile_keys } from '../../utils/fields';
import { persistor } from '../../stores/configureStore';
//Actions

export function processLoginCredentials(userSession) {
  const maintain_profile = {};

  profile_keys.forEach((key) => {
    maintain_profile[key] =
      userSession?.data?.user[key] !== null ? userSession?.data?.user[key] : '';
  });

  return {
    type: actionTypes.USER_SESSION,
    userSession: userSession?.data?.user,
    isAuthenticated: userSession.status,
    maintain_profile1: maintain_profile,
  };
}
export function logoutUser(history) {
  return (dispatch) => {
    persistor.pause();
    dispatch(processLoginCredentials({}));
    persistor.purge();
    history.push('/login');
  };
}
export function profile(profile) {
  const { maintain_profile } = store.getState().users;
  return {
    type: actionTypes.MAINTAIN_PROFILE,
    maintain_profile: { ...maintain_profile, ...profile },
  };
}

export function add_a_field_profile(field) {
  const { maintain_profile } = store.getState().users;
  return {
    type: actionTypes.ADD_MAINTAIN_PROFILE,
    maintain_profile: { ...maintain_profile, check_my_medications: field },
  };
}

export function userAddresses(userAddresses) {
  return {
    type: actionTypes.USER_ADDRESS,
    userAddresses: userAddresses,
  };
}

export function process_states(data) {
  return {
    type: actionTypes.GET_STATES,
    states: data.data.states,
  };
}

export function process_cities(data) {
  return {
    type: actionTypes.GET_CITIES,
    cities: data.data.cities,
  };
}

export function searched_medicines(data) {
  return {
    type: actionTypes.SEARCH_MEDICINES,
    medicines: data.data,
  };
}

export function empty_searched_medicines() {
  return {
    type: actionTypes.EMPTY_SEARCH_MEDICINES,
    medicines: [],
  };
}

export function store_session_id(data) {
  return {
    type: actionTypes.SET_SESSION_ID,
    stripe_session: data.data,
  };
}

export function handle_profile_exception() {
  return {
    type: actionTypes.REQUIRE_LOGIN,
  };
}

export function add_medicine_for_me(value) {
  const { maintain_profile } = store.getState().users;
  return {
    type: actionTypes.ADD_MAINTAIN_PROFILE,
    maintain_profile: { ...maintain_profile, add_medicine_for_me: value },
  };
}

//Api calls

export function getUserSession(username, password, history, redirect) {
  let params = {
    email: username.toString(),
    password: password.toString(),
    enviroment: 'WEB',
  };
  persistor.persist();
  return post(
    EndPoint.USER_SESSION,
    params,
    processLoginCredentials,
    redirect,
    history
  );
}

export function update_profile_in_DB(profile) {
  return put(EndPoint.UPDATE_PROFILE, profile, null, null);
}

export function getUserAddresses(userId) {
  return get(EndPoint.USER_ADDRESS, userId, userAddresses, null);
}

export function populate_states(country_id) {
  return get(EndPoint.GET_STATES, country_id, process_states, null);
}

export function populate_cities(state_id) {
  return get(EndPoint.GET_CITIES, state_id, process_cities, null);
}

export function search_medicines(medicine) {
  if (medicine.length > 2) {
    return get(EndPoint.SEARCH_MEDICINES, medicine, searched_medicines, null);
  } else {
    return empty_searched_medicines();
  }
}

export function get_stripe_session_id(personQuantity, add_medicine_for_me = false) {
  const { maintain_profile } = store.getState().users;
  const final_maintain_profile = {
    ...maintain_profile,
    number_of_persons: personQuantity,
    add_medicine_for_me: add_medicine_for_me,
  };
  return post(
    EndPoint.STRIPE_SESSION_ID,
    final_maintain_profile,
    store_session_id,
    null
  );
}
