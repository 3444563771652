import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../../stores/configureStore';
import PrivateRoute from './Routes/PrivateRoutes';
import { privateRoutes, publicRoutes } from './Routes/Routes';
import PublicRoutes from './Routes/PublicRoutes';

const Root = () => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Router>
                    <div>
                         <Switch>
                            {
                                publicRoutes.map((route, index) => {
                                    return (
                                        <PublicRoutes key={index} exact={route.exact} path={route.path} component={route.component} />
                                    )
                                })
                            }
                            {
                                privateRoutes.map((route, index) => {
                                    return (
                                        <PrivateRoute key={index} exact={route.exact} path={route.path} component={route.component} />
                                    )
                                })
                            }
                            <Route path="*" component={() => "404 NOT FOUND"} />
                        </Switch>
                    </div>
                </Router>
            </PersistGate>
        </Provider>
    );
};

export default Root;
