import { get, post, del } from './api';
import * as actionTypes from './constant/actionTypes';
import * as EndPoint from './constant/EndPoint';

export function medicine_list(data) {
  return {
    type: actionTypes.MEDICINE_LIST,
    medicine_list: data.data,
  };
}

export function populate_mg(data) {
  return {
    type: actionTypes.MG_RANGE,
    mg_range: data.data,
  };
}

export function resetMgRange() {
  return {
    type: actionTypes.MG_RANGE,
    mg_range: [],
  };
}

/**
 * API calls
 */

export function populate_previous_medicines() {
  return get(EndPoint.GET_MEDICINES, '', medicine_list, null);
}

export function add_medicine(medicine) {
  return post(
    EndPoint.ADD_MEDICINES,
    medicine,
    populate_previous_medicines,
    null
  );
}

export function delete_medicine(id) {
  return del(EndPoint.DEL_MEDICINE, id, populate_previous_medicines, null);
}

export function get_mg_range(medicine_name) {
  return get(EndPoint.MG_RANGE, medicine_name, populate_mg, null);
}
