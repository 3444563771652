import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../redux/actions';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import images from '../../images/HHHMM.png';

const Login = (props) => {
  const [loginCredentials, setLoginCredentials] = useState({
    username: '',
    password: '',
  });
  const [incorrect, setIncorrect] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const captureChange = (event) => {
    setLoginCredentials({
      ...loginCredentials,
      [event.target.name]: event.target.value,
    });
  };

  const loginAttempt = async (event) => {
    event.preventDefault();
    const result = await props.login(
      loginCredentials.username,
      loginCredentials.password,
      props.history,
      '/profile-build'
    );
    if (result === false) {
      setIncorrect(!result);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src={images} className="logo d-block d-md-none" width="200" alt="logo" />
            <div className="login-bg"> </div>
          </div>
          <div className="col-md-6">
            <img
              src={images}
              className="logo d-none d-md-block"
              width="170"
              alt="logo"
            />
            <Form className="login-form" onSubmit={(e) => loginAttempt(e)}>
              {incorrect && <p>Incorrect username or password</p>}
              <Form.Group>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Enter your username"
                  onChange={(e) => captureChange(e)}
                />
              </Form.Group>

              <Form.Group className="last">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type={!showPassword ? 'password' : 'text'}
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  onChange={(e) => captureChange(e)}
                />
                <span
                  style={{
                    position: 'relative',
                    float: 'right',
                    right: 16,
                    bottom: 33,
                    cursor: 'pointer',
                  }}
                >
                  {!showPassword ? (
                    <FontAwesomeIcon
                      icon={faEye}
                      onClick={() => setShowPassword(true)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      onClick={() => setShowPassword(false)}
                    />
                  )}
                </span>
              </Form.Group>
              <a href="#"></a>
              <Button
                type="submit"
                variant="primary"
                disabled={
                  !(loginCredentials.username && loginCredentials.password)
                }
                // onClick={() => loginAttempt()}
              >
                Login
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.users,
    isAuthenticated: state.users.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: bindActionCreators(actions.getUserSession, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
