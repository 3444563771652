export const profile_keys = [
  'first_name',
  'legal_first_name',
  'middle_initial',
  'last_name',
  'dob',
  'medicare_number',
  'a_date',
  'b_date',
  'address',
  'city_name',
  'state_id',
  'zip_code',
  'phone',
  'phone_no_type',
  'email',
  'phone_emergency_contact_name',
  'phone_emergency',
  'phone_emergency_contact_relationship',
  'contact_relationship_others',
  'social_benefits',
  'social_benefits_paid_automatically',
  'check_my_medications',
  'is_profile_changed',
  'what_changed_in_profile',
  'is_pharmacy_address_changed',
  'pharmacy_address',
  'social_benefits_changed_last_year',
];

export const profile_keys_checks = {
  first_name: {
    error: "Please Enter Your First Name",
    required: true,
  },
  legal_first_name: {
    error: "Please Enter Your Legal First Name",
    required: false,
  },
  middle_initial: {
    error: "Please Enter Your Middle Name",
    required: false,
  },
  last_name: {
    error: "Please Enter Your Last Name",
    required: true,
  },
  dob: {
    error: "Please Enter Your Date Of Birth",
    required: true,
  },
  medicare_number: {
    error: "Please Enter Your Medicare Number",
    required: false,
  },
  address: {
    error: "Please Enter Your Address",
    required: true,
  },
  city_name: {
    error: "Please Select Your City",
    required: true,
  },
  state_id: {
    error: "Please Select Your State",
    required: true,
  },
  zip_code: {
    error: "Enter You Zip Code",
    required: true,
  },
  phone: {
    error: "Enter Your Contact Number",
    required: true,
  },
  phone_no_type: {
    error: "Select Your Phone Type",
    required: true,
  },
  email: {
    error: "Enter Your Email",
    required: true,
  },
  phone_emergency_contact_name: {
    error: "Enter Emergency Contact Name",
    required: true,
  },
  phone_emergency: {
    error: "Enter Emergency Contact Phone",
    required: true,
  },
  phone_emergency_contact_relationship: {
    error: "Select Your Relationship",
    required: true,
    hasChild: false,
    child: {
      contact_relationship_others: {
        error: "Enter Your Relationship",
      },
    },
  },
  social_benefits: {
    error: "Do you Take Social Benefits?",
    required: true,
  },
  social_benefits_paid_automatically: {
    error: "Pay Social benefits automatically?",
    required: true,
  },
  check_my_medications: {
    error: "Prescription Plan required",
    required: true,
  },
  is_profile_changed: {
    error: "Is Your Profile Changed?",
    required: true,
    hasChild: false,
    child: {
      what_changed_in_profile: {
        error: "What Changed in your Profile",
      },
    },
  },
  is_pharmacy_address_changed: {
    error: "Pharmacy Changed or Not?",
    required: false,
    hasChild: false,
    child: {
      pharmacy_address: {
        error: "Enter Your New Pharmacy Address",
      },
    },
  },

  social_benefits_changed_last_year: {
    error: "Is Your Social Benefits Changed Last Year",
    required: true,
  },
};

export const profile_keys_messages = {
  first_name: {
    error: 'Please Enter Your First Name',
  },
  legal_first_name: {
    error: 'Please Enter Your Legal First Name',
  },
  middle_initial : {
    error: 'Please Enter Your Middle Name',
  },
  last_name: {
    error: 'Please Enter Your Last Name',
  },
  dob: {
    error: 'Please Enter Your Date Of Birth',
  },
  medicare_number: {
    error: 'Please Enter Your Medicare Number',
  },
  address: {
    error: 'Please Enter Your Address',
  },
  state_id: {
    error: 'Please Select Your State',
  },
  city_name: {
    error: 'Please Select Your City',
  },
  zip_code: {
    error: 'Enter You Zip Code',
  },
  phone: {
    error: 'Enter Your Contact Number',
  },
  phone_no_type: {
    error: 'Select Your Phone Type',
  },
  email: {
    error: 'Enter Your Email',
  },
  phone_emergency_contact_name: {
    error: 'Enter Emergency Contact Name',
  },
  phone_emergency: {
    error: 'Enter Emergency Contact Phone',
  },
  phone_emergency_contact_relationship: {
    error: 'Select Your Relationship',
  },
  // contact_relationship_others: { error: 'Select Your Relationship' },
  social_benefits: {
    error: 'Do you Take Social Benefits?',
  },
  social_benefits_paid_automatically: {
    error: 'Pay Social benefits automatically?',
  },
  check_my_medications: {
    error: 'Perscription Plan required',
  },
  // is_profile_changed: {
  //   error: 'Is Your Profile Changed?',
  // },
  // what_changed_in_profile: {
  //   error: 'What Changed in your Profile',
  // },
  // is_pharmacy_address_changed: {
  //   error: 'Pharmacy Changed or Not?',
  // },
  // pharmacy_address: {
  //   error: 'Enter Your New Pharmacy Address',
  // },
  social_benefits_changed_last_year: {
    error: 'Is Your Social Benefits Changed Last Year',
  },
};
