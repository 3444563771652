import React, { useEffect } from 'react';
import images from '../../images/logo.png';
import imageGood from '../../images/good.png';
// import imagePharmacy from '../../images/pharmacy.png';
import loginBg from '../../images/login-bg.png';
// import imageTick from '../../images/tick.svg';
import { useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
const messageArray = [
  {
    successMessage: {
      h1: `You're Done! The rest is up to us!`,
      p: `Help My Medicare deeply appreciates 
          you taking the time to enlist our services! 
          We value your trust and will get back with you as soon as we can!`,
    },
    endMessage: {
      h1: "You're Done!\n You're still a valued HMM client.",
      p: `We appreciate you updating your personal information.
          Please contact us anytime you have questions.
          You can now Logout.`,
    },
  },
];
const Success = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 top-banner">
          <img src={images} width="170" alt="logo" />
        </div>
        <div className="col-12">
          <div className="success-details">
            <img src={imageGood} alt="good" />
            {messageArray.map((message) =>
              props.location?.state?.endMessage ? (
                <React.Fragment key={message}>
                  <h1
                    style={{
                      whiteSpace: 'pre-line',
                      textAlign: 'center',
                      lineHeight: 1.2,
                      fontSize: 34,
                    }}
                  >
                    {message.endMessage.h1}
                  </h1>
                  <p
                    style={{
                      color: 'black',
                      fontWeight: 550,
                      fontSize: 22,
                      paddingTop: 10,
                    }}
                  >
                    {message.endMessage.p}
                  </p>{' '}
                </React.Fragment>
              ) : (
                <React.Fragment key={message}>
                  <h1
                    style={{
                      fontSize: 34,
                    }}
                  >
                    {message.successMessage.h1}
                  </h1>
                  <p
                    style={{
                      fontSize: 22,
                    }}
                  >
                    {message.successMessage.p}
                  </p>{' '}
                </React.Fragment>
              )
            )}
            <img src={loginBg} alt="good" />
            <div>
              {/* <button className="btn btn-primary" type="button">
                <i className="tick">
                  <img src={imageTick} alt="delete" />
                </i>{' '}
                Okay
              </button> */}
              <button
                className="btn btn-primary"
                type="button"
                style={{ marginLeft: 10 }}
                onClick={() => dispatch(actions.logoutUser(props.history))}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
