import React, { useState, useEffect, useCallback, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { Modal, Button, Toast } from 'react-bootstrap';
import _ from 'lodash';

import imageCross from '../../images/crosss.svg';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';

import { profile_keys_checks } from '../../utils/fields';
// import { useHistory } from 'react-router-dom';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import { Spinner } from 'react-bootstrap';
import { setHasChildField } from '../../utils/setHasChildField';
import Select from "react-select";
import moment from 'moment';

const Step1 = (props) => {
  const [dialog2, setDialog2] = useState(false);
  const [dialog4, setDialog4] = useState(false);
  const [dialog5, setDialog5] = useState(false);
  const [error, setError] = useState(false);
  const [step1State, setStep1State] = useState(null);

  const [missingFields, setMissingFields] = useState([]);
  const profileKeysChecks = useRef(_.cloneDeep(profile_keys_checks));
  const { states, maintain_profile, userSession } = useSelector(
    (state) => state.users
  );

  let options=[]
  if(Array.isArray(states)){
    options = states.map((state) => {
      const states = { value: state.id, label: state.name, stateId: state.id };
      return states;
    });
  }
  const stateId = step1State?step1State.state_id:null
  const selectedState = options.find(sd => sd.stateId === stateId);

  const { isLoading } = useSelector((state) => state.service);

  const colourStyles = {
    control: styles => ({ ...styles,  maxHeight:'48px',cursor: "text"  }),
    option: (styles) => {
      return {
        ...styles,
        cursor: "pointer",
      };
    },
    input: styles => {return{
      ...styles,
      cursor: "pointer",
    }},
    singleValue: (styles => ({ ...styles, color: 'black',fontSize: '1.5rem',top: '40%'  }))
  };
  const changesWithInLastYear = maintain_profile?.what_changed_in_profile
    ? maintain_profile?.what_changed_in_profile
        ?.split(',')
        .filter((value) => value !== '')
    : [];
  const [changesInProfile, setChangesInProfile] = useState(
    maintain_profile?.what_changed_in_profile
  );
  const dispatch = useDispatch();

  const changes_in_profile = useRef([
    {
      label: 'Name',
      checked: changesWithInLastYear.find((value) => value === 'Name')
        ? true
        : false,
      value: 'Name,',
    },
    {
      label: 'Address',
      checked: changesWithInLastYear.find((value) => value === 'Address')
        ? true
        : false,
      value: 'Address,',
    },
    {
      label: 'Pharmacy',
      checked: changesWithInLastYear.find((value) => value === 'Pharmacy')
        ? true
        : false,
      value: 'Pharmacy,',
    },
    {
      label: 'City',
      checked: changesWithInLastYear.find((value) => value === 'City')
        ? true
        : false,
      value: 'City,',
    },
    {
      label: 'State',
      checked: changesWithInLastYear.find((value) => value === 'State')
        ? true
        : false,
      value: 'State,',
    },
    {
      label: 'Zip',
      checked: changesWithInLastYear.find((value) => value === 'Zip')
        ? true
        : false,
      value: 'Zip,',
    },
    {
      label: 'Primary Contact',
      checked: changesWithInLastYear.find(
        (value) => value === 'Primary Contact'
      )
        ? true
        : false,
      value: 'Primary Contact,',
    },
    {
      label: 'Email',
      checked: changesWithInLastYear.find((value) => value === 'Email')
        ? true
        : false,
      value: 'Email,',
    },
    {
      label: 'Emergency Contact Name',
      checked: changesWithInLastYear.find(
        (value) => value === 'Emergency Contact Name'
      )
        ? true
        : false,
      value: 'Emergency Contact Name,',
    },
    {
      label: 'Emergency Contact Number',
      checked: changesWithInLastYear.find(
        (value) => value === 'Emergency Contact Number'
      )
        ? true
        : false,
      value: 'Emergency Contact Number,',
    },
    {
      label: 'Relationship',
      checked: changesWithInLastYear.find((value) => value === 'Relationship')
        ? true
        : false,
      value: 'Relationship,',
    },
  ]);
  const profileChangesHandler = (value, change) => {
    if (value === 'Pharmacy,' && change.checked === true) {
      setStep1State({
        ...step1State,
        is_pharmacy_address_changed: 1,
      });
    }
    if (value === 'Pharmacy,' && change.checked === false) {
      setStep1State({
        ...step1State,
        is_pharmacy_address_changed: 0,
        pharmacy_address: '',
      });
    }
    if (change.checked === false) {
      setChangesInProfile(changesInProfile.replace(change.value, ''));
    } else {
      setChangesInProfile((prevValue) => `${prevValue}${value}`);
    }
  };
  const captureChange = (event) => {
    if (event.target.name === 'phone_emergency_contact_relationship') {
      setStep1State({
        ...step1State,
        [event.target.name]: event.target.value,
      });
    } else if(event.target.name === 'dob'){
      setStep1State({
        ...step1State,
        [event.target.name]: event.target.value !== '' ? moment(event.target.value).format("YYYY-MM-DD") : '',
      });
    } else {
      setStep1State({
        ...step1State,
        [event.target.name]:
          event.target.type === 'radio'
            ? +event.target.value
            : event.target.value,
      });
    }
  };
  const removeChange = () => {
    setStep1State({
      ...step1State,
      city_id: '',
    });
  };

  const hybridChange = async (event) => {
    setStep1State({
      ...step1State,
      ['state_id']: event.stateId ? event.stateId : null,
      city_id: '',
    });
  };
  const verify_fields = useCallback(() => {
    let temp = [];
    _.forEach(profileKeysChecks.current, (value, key) => {
      if (step1State[key] === '' && value?.required === true) {
        temp.push(value?.error);
      }

      if (profileKeysChecks.current[key]?.hasOwnProperty('hasChild')) {
        setHasChildField(profileKeysChecks.current, step1State, key);
      }

      if (profileKeysChecks.current[key]?.hasChild === true) {
        _.forEach(value?.child, (value2, key2) => {
          if (step1State[key2] === '') {
            temp.push(value2.error);
          }
        });
      }
    });
    return temp;
  }, [step1State]);

  const update_profile = (thinkingToAddMedicine) => {
    let updateProfile = {
      ...step1State,
      thinking_to_add_medicine: thinkingToAddMedicine,
      add_medicine_for_me: !!userSession.add_medicine_for_me ?? false,
    };
    dispatch(actions.update_profile_in_DB(updateProfile));
    dispatch(actions.profile(updateProfile));
    props.setSection(props.section + 1);
  };

  const onCheckMyMedication = (value) => {
    let newStep1State = { ...step1State };
    newStep1State['check_my_medications'] = +value;
    dispatch(actions.update_profile_in_DB(newStep1State));
    dispatch(actions.profile(newStep1State));
    props.history.push({
      pathname: '/success',
      state: { endMessage: true },
    });
  };

  useEffect(() => {
    dispatch(actions.populate_states(95));
  }, [dispatch]);

  useEffect(() => {
    setStep1State({
      ...step1State,
      what_changed_in_profile: changesInProfile,
    });
  }, [changesInProfile]);

  useEffect(() => {
    if (step1State) {
      let newFields = verify_fields();
      setMissingFields(newFields);
    }
  }, [verify_fields, step1State]);

  useEffect(() => {
    if(maintain_profile){
      maintain_profile.check_my_medications=""
    }
    setStep1State(maintain_profile);
  }, [maintain_profile]);

  if (step1State === null) {
    return (
      <div style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '100vw', textAlign: 'center' }}>
          <Spinner
            animation={'border'}
            variant="primary"
            style={{ width: '5rem', height: '5rem' }}
          />
        </div>
      </div>
    );
  }
  const step1Array = [
    <Form className="row" key={1} onSubmit={(event) => event.preventDefault()}>
      <Form.Group className="col-md-3">
        <label>First Name</label>
        <input
          onChange={(e) => {
            captureChange(e);
          }}
          type="text"
          id="first_name"
          name="first_name"
          placeholder="first name"
          defaultValue={step1State.first_name}
        />
      </Form.Group>
      <Form.Group className="col-md-3">
        <label>Legal First Name</label>
        <input
          onChange={(e) => {
            captureChange(e);
          }}
          type="text"
          id="legal_first_name"
          name="legal_first_name"
          placeholder="legal first name"
          defaultValue={step1State.legal_first_name}
        />
      </Form.Group>
      <Form.Group className="col-md-3">
        <label>Middle Name</label>
        <input
          onChange={(e) => {
            captureChange(e);
          }}
          type="text"
          id="middle_initial"
          name="middle_initial"
          placeholder="Middle Name"
          defaultValue={step1State.middle_initial}
        />
      </Form.Group>
      <Form.Group className="col-md-3">
        <label>Last Name</label>
        <input
          onChange={(e) => {
            captureChange(e);
          }}
          type="text"
          id="last_name"
          name="last_name"
          placeholder="last name"
          defaultValue={step1State.last_name}
        />
      </Form.Group>
    </Form>,
    <Form className="row" key={2}>
      <Form.Group className="col-md-3">
        <label>Date of Birth*</label>
        <input
          onChange={(e) => captureChange(e)}
          type="date"
          id="dob"
          name="dob"
          defaultValue={
              moment(step1State.dob).format("YYYY-MM-DD")
          }
        />
      </Form.Group>
      <Form.Group className="col-md-3">
        <label>Medicare Number (If Any)</label>
        <input
          onChange={(e) => captureChange(e)}
          type="text"
          id="medicare_number"
          name="medicare_number"
          placeholder="medicare number"
          defaultValue={step1State.medicare_number}
        />
      </Form.Group>
      <Form.Group className="col-md-3">
        <label>A Date (If Any)</label>
        <input
          onChange={(e) => captureChange(e)}
          type="date"
          id="a_date"
          name="a_date"
          defaultValue={step1State.a_date}
          placeholder="if any..."
        />
      </Form.Group>
      <Form.Group className="col-md-3">
        <label>B Date (If Any)</label>
        <input
          onChange={(e) => captureChange(e)}
          type="date"
          id="b_date"
          name="b_date"
          placeholder="if any..."
          defaultValue={step1State.b_date}
        />
      </Form.Group>
    </Form>,
    <Form className="row" key={17}>
      <Form.Group className="col-md-10">
        <label>Address</label>
        <input
          onChange={(e) => captureChange(e)}
          type="text"
          id="address"
          name="address"
          placeholder="address"
          defaultValue={step1State.address}
        />
      </Form.Group>
    </Form>,
    <Form
      className="d-flex row"
      key={3}
      onSubmit={(event) => event.preventDefault()}
    >
      <Form.Group className="col-md-4 col-lg-3 ">
        <label>City</label>
        <input
          onChange={(e) => captureChange(e)}
          type="text"
          id="city"
          name="city_name"
          placeholder="city name"
          defaultValue={step1State.city_name}
        />
        {/* <label>City</label>
                <select onChange={e => captureChange(e) } type='select' id='city' name='city_id' value={step1State.city_id}>
                    <option value=''>Select City</option>
                    {
                        cities?.map(city => {
                            return (
                                <option key={city.id} value={city.id}>{city.name}</option>
                            )
                        })
                    }
                </select> */}
      </Form.Group>
      <Form.Group className="col-md-4 col-lg-3 ">
        <label>State</label>
        <Select
          placeholder="Select State"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          styles={colourStyles}
          options={options}
          defaultValue={selectedState ? selectedState : null}
          onChange={(e) => {
            e.value !== "" ? hybridChange(e) : removeChange();
          }}
        />
      </Form.Group>
      <Form.Group className="col-md-4 col-lg-3">
        <label>Zip Code</label>
        <input
          onChange={(e) => captureChange(e)}
          type="text"
          id="zipcode"
          name="zip_code"
          placeholder="zip code"
          defaultValue={step1State.zip_code}
        />
      </Form.Group>
    </Form>,
    <Form
      className="d-flex row"
      key={4}
      onSubmit={(event) => event.preventDefault()}
    >
      <Form.Group className="col-md-5">
        <label>Contact No. (Primary)</label>
        <input
          onChange={(e) => captureChange(e)}
          type="tel"
          id="contact"
          name="phone"
          placeholder="phone no"
          defaultValue={step1State.phone}
        />
      </Form.Group>
      <Form.Group className="col-md-6 d-flex step3 align-grid-wrapper">
        <p>This is my</p>
        <div className="align-grid">
          <Form.Check
            type="radio"
            label="Cell"
            name="phone_no_type"
            id="formHorizontalRadios2"
            value={2}
            onChange={(e) => captureChange(e)}
            checked={step1State.phone_no_type === 2}
          />
          <Form.Check
            type="radio"
            label="Home"
            name="phone_no_type"
            id="formHorizontalRadios1"
            value={1}
            onChange={(e) => captureChange(e)}
            checked={step1State.phone_no_type === 1}
          />
          <p>number</p>
        </div>
      </Form.Group>
    </Form>,
    <Form className="row" key={5} onSubmit={(event) => event.preventDefault()}>
      <Form.Group className="col-md-5">
        <label>Email Address</label>
        <input
          onChange={(e) => captureChange(e)}
          type="email"
          id="email"
          name="email"
          placeholder="email address"
          defaultValue={step1State.email}
        />
      </Form.Group>
    </Form>,
    <Form className="row" key={6} onSubmit={(event) => event.preventDefault()}>
      <Form.Group className="col-md-5">
        <label>Emergency Contact Name</label>
        <input
          onChange={(e) => captureChange(e)}
          type="text"
          id="emergency_contact"
          name="phone_emergency_contact_name"
          placeholder="emergency contact"
          defaultValue={step1State.phone_emergency_contact_name}
        />
      </Form.Group>
    </Form>,
    <Form className="row" key={7} onSubmit={(event) => event.preventDefault()}>
      <Form.Group className="col-md-5">
        <label>Emergency Contact Number</label>
        <input
          onChange={(e) => captureChange(e)}
          type="tel"
          id="emergency_number"
          name="phone_emergency"
          placeholder="emergency no"
          defaultValue={step1State.phone_emergency}
        />
      </Form.Group>
    </Form>,
    <Form key={8} onSubmit={(event) => event.preventDefault()}>
      <div className="row">
        <div className="col-12">
          <label>Relationship to you</label>
        </div>
      </div>
      <div className="row step3 step8">
        <Form.Group className="col-md-3 col-lg-2">
          <Form.Check
            type="radio"
            label="Spouse"
            name="phone_emergency_contact_relationship"
            id="formHorizontalRadios3"
            value={"spouse"}
            onChange={(e) => {
              profileKeysChecks.current = {
                ...profileKeysChecks.current,
                phone_emergency_contact_relationship: {
                  ...profileKeysChecks.current
                    .phone_emergency_contact_relationship,
                  hasChild: false,
                },
              };
              captureChange(e);
            }}
            checked={
              step1State.phone_emergency_contact_relationship.toLowerCase() ===
              "spouse"
            }
          />
        </Form.Group>
        <Form.Group className="col-md-3 col-lg-2">
          <Form.Check
            type="radio"
            label="Son"
            name="phone_emergency_contact_relationship"
            id="formHorizontalRadios4"
            value={"son"}
            onChange={(e) => {
              profileKeysChecks.current = {
                ...profileKeysChecks.current,
                phone_emergency_contact_relationship: {
                  ...profileKeysChecks.current
                    .phone_emergency_contact_relationship,
                  hasChild: false,
                },
              };
              captureChange(e);
            }}
            checked={
              step1State.phone_emergency_contact_relationship.toLowerCase() ===
              "son"
            }
          />
        </Form.Group>
        <Form.Group className="col-md-3 col-lg-2">
          <Form.Check
            type="radio"
            label="Daughter"
            name="phone_emergency_contact_relationship"
            id="formHorizontalRadios5"
            value={"daughter"}
            onChange={(e) => {
              profileKeysChecks.current = {
                ...profileKeysChecks.current,
                phone_emergency_contact_relationship: {
                  ...profileKeysChecks.current
                    .phone_emergency_contact_relationship,
                  hasChild: false,
                },
              };
              captureChange(e);
            }}
            checked={
              step1State.phone_emergency_contact_relationship.toLowerCase() ===
              "daughter"
            }
          />
        </Form.Group>
        <Form.Group className="col-md-3 col-lg-2">
          <Form.Check
            type="radio"
            label="Other"
            name="phone_emergency_contact_relationship"
            id="formHorizontalRadios6"
            value={"other"}
            onChange={(e) => {
              profileKeysChecks.current = {
                ...profileKeysChecks.current,
                phone_emergency_contact_relationship: {
                  ...profileKeysChecks.current
                    .phone_emergency_contact_relationship,
                  hasChild: true,
                },
              };
              captureChange(e);
            }}
            defaultChecked={
              step1State.phone_emergency_contact_relationship.toLowerCase() ===
              "other"
            }
          />
        </Form.Group>
      </div>
      <div className="row ">
        {step1State.phone_emergency_contact_relationship.toLowerCase() ===
          "other" && (
          <Form.Group className="col-md-5">
            <input
              onChange={(e) => captureChange(e)}
              type="text"
              id="contact_relationship_others"
              name="contact_relationship_others"
              defaultValue={step1State.contact_relationship_others ?? ""}
            />
          </Form.Group>
        )}
      </div>
    </Form>,
    <div key={18}>
      <label style={{ fontWeight: "bold" }}>
        Enter Pharmacy Name(CVS, Walgreens, etc.). You can include more than one
        if needed.
      </label>
      <div className="row ">
        <div className="col-md-5">
          <input
            className="margin-bottom"
            onChange={(e) => captureChange(e)}
            type="text"
            id="pharmacy_address"
            name="pharmacy_address"
            value={step1State.pharmacy_address ?? ""}
          />
        </div>
      </div>
    </div>,
    <Form key={15} onSubmit={(event) => event.preventDefault()}>
      <div className="row ">
        <div className="col-12">
          <label>
            Has any of the above information changed within the last year?
          </label>
        </div>
      </div>
      <div className="row step3 step8">
        <Form.Group className="col-md-2">
          <Form.Check
            type="radio"
            label="Yes"
            name="is_profile_changed"
            id="is_profile_changed formHorizontalRadios8"
            value={1}
            onChange={(e) => {
              captureChange(e);
            }}
            onClick={() => setDialog4(true)}
            checked={step1State.is_profile_changed === 1}
          />
        </Form.Group>
        <Form.Group className="col-md-2 no-class">
          <Form.Check
            type="radio"
            label="No"
            name="is_profile_changed"
            id="is_profile_changed formHorizontalRadios9"
            value={0}
            onChange={(e) => {
              captureChange(e);
              setDialog4(false);
            }}
            checked={step1State.is_profile_changed === 0}
          />
        </Form.Group>
      </div>
    </Form>,

    <Modal show={dialog4} onHide={() => setDialog4(false)} key={16}>
      <Modal.Body>
        <div className="popup-form ">
          <div className="section-top">
            <i className="cross">
              <img
                src={imageCross}
                alt="cross"
                onClick={() => setDialog4(false)}
              />
            </i>
            <Form
              className="row step3 step8"
              onSubmit={(event) => event.preventDefault()}
            >
              <label style={{ fontWeight: "bold" }}>
                What Changed in your Profile within Last Year?
              </label>
              {changes_in_profile.current.map((change) => (
                <Form.Group className="col-md-10 no-class" key={change.label}>
                  <Form.Check
                    type="checkbox"
                    label={change.label}
                    value={change.value}
                    defaultChecked={change.checked}
                    onChange={(e) => {
                      change.checked = !change.checked;
                      profileChangesHandler(e.target.value, change);
                    }}
                  />
                </Form.Group>
              ))}
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>,

    <Form key={9} onSubmit={(event) => event.preventDefault()}>
      <div className="row">
        <div className="col-12">
          <label>Are you currently taking your Social Security benefits?</label>
        </div>
      </div>
      <div className="row step3 step8">
        <Form.Group className="col-md-2">
          <Form.Check
            type="radio"
            label="Yes"
            name="social_benefits"
            id="social_benefits formHorizontalRadios6"
            value={1}
            onChange={(e) => captureChange(e)}
            checked={step1State.social_benefits === 1}
          />
        </Form.Group>
        <Form.Group className="col-md-2 no-class">
          <Form.Check
            type="radio"
            label="No"
            name="social_benefits"
            id="social_benefits formHorizontalRadios7"
            value={0}
            onChange={(e) => captureChange(e)}
            checked={step1State.social_benefits === 0}
          />
        </Form.Group>
      </div>
    </Form>,
    <Form key={14} onSubmit={(event) => event.preventDefault()}>
      <div className="row">
        <div className="col-12">
          <label>Has this changed in the past year?</label>
        </div>
      </div>
      <div className="row step3 step8">
        <Form.Group className="col-md-2">
          <Form.Check
            type="radio"
            label="Yes"
            name="social_benefits_changed_last_year"
            id="social_benefits_changed_last_year formHorizontalRadios8"
            value={1}
            onChange={(e) => captureChange(e)}
            checked={step1State.social_benefits_changed_last_year === 1}
          />
        </Form.Group>
        <Form.Group className="col-md-2 no-class">
          <Form.Check
            type="radio"
            label="No"
            name="social_benefits_changed_last_year"
            id="social_benefits_changed_last_year formHorizontalRadios9"
            value={0}
            onChange={(e) => captureChange(e)}
            checked={step1State.social_benefits_changed_last_year === 0}
          />
        </Form.Group>
      </div>
    </Form>,

    <Form
      key={10}
      className="prescriptions-details"
      onSubmit={(event) => event.preventDefault()}
    >
      <div className="row">
        <div className="col-12">
          <label>
            We love evaluating your Part D Plan! Shall we do it this year?
          </label>
        </div>
      </div>
      <div className="row">
        <Form.Group className="col-12">
          <Form.Check
            type="radio"
            label="Yes! I want peace of mind and possibly financial savings! Let's get started"
            name="check_my_medications"
            id="formHorizontalRadios10 yes"
            readOnly
            onClick={() => setDialog5(true)}
            checked={step1State.check_my_medications === 1}
          />
        </Form.Group>
        <Form.Group className="col-12 no-class">
          <Form.Check
            type="radio"
            label="No, I’m just hoping things will be ok."
            name="check_my_medications"
            id="formHorizontalRadios10 No"
            readOnly
            onClick={() => setDialog2(true)}
            checked={step1State.check_my_medications === 0}
          />
        </Form.Group>
      </div>
      <Modal show={dialog5}>
        <Modal.Body>
          <Form
            className="popup-form"
            onSubmit={(event) => event.preventDefault()}
          >
            <i
              className="cross"
              style={{ cursor: "pointer" }}
              onClick={() => setDialog5(false)}
            >
              <img src={imageCross} alt="cross" />
              <span style={{ fontStyle: "normal", marginLeft: 5 }}>close</span>
            </i>
            <h5>Smart Choice!</h5>
            <p>
              We have saved our clients more than{" "}
              <span style={{ fontWeight: "bold" }}>$500,000</span>! The average
              savings is <span style={{ fontWeight: "bold" }}>$1000-$3000</span>{" "}
              per person.
            </p>
            <p>
              Even if there are no monetary savings, you definitely gain peace
              of mind knowing you are in the best plan for YOU for this coming
              year.
            </p>
            <p>We assess a fee even if research shows no changes are needed.</p>
            <p>
              The results are valuable to you. Our fee is{" "}
              <span style={{ fontWeight: "bold" }}>$250</span> per person.
            </p>
            <p>
              We compare your specific drugs with every stand- alone drug plan
              offered in your state to determine which plan is best for you.
            </p>
            <p>
              We take into consideration your pharmacy of choice but also let
              you know if switching pharmacies would save you money.
            </p>{" "}
            <p>
              We advise you of any special restriction Medicare Drug Plans may
              have on any of your drugs.
            </p>{" "}
            <p>
              We are available to help you with options if you have a new
              non-covered drug prescribed after the Enrollment Period.
            </p>
            <div className="row">
              <Form.Group>
                <Form.Check
                  className=" no-class"
                  type="radio"
                  label="I understand and want to continue."
                  name="check_my_medications"
                  value={1}
                  onChange={(e) => {
                    captureChange(e);
                  }}
                  checked={step1State.check_my_medications === 1}
                />
                {step1State.check_my_medications === 1 && (
                  <p style={{ marginLeft: 30 }}>
                    Great! Close this box and proceed to the question about
                    paying your Part D premium.
                  </p>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Check
                  className=" no-class"
                  type="radio"
                  label="I understand and want to decline an evaluation."
                  name="check_my_medications"
                  value={0}
                  onChange={(e) => onCheckMyMedication(e.target.value)}
                  checked={step1State.check_my_medications === 0}
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={dialog2}>
        <Modal.Body>
          <Form
            className="popup-form"
            onSubmit={(event) => event.preventDefault()}
          >
            <i className="cross">
              <img
                src={imageCross}
                alt="cross"
                onClick={() => setDialog2(false)}
              />
            </i>
            <h5>Are you sure?</h5>
            <Form.Group>
              <Form.Check
                type="radio"
                label="Yes, I will take the risk"
                name="check_my_medications"
                id="formHorizontalRadios11 yes"
                value={0}
                onChange={(e) => onCheckMyMedication(e.target.value)}
                checked={step1State.check_my_medications === 0}
              />
              {/* {step1State.check_my_medications === 1 && (
                <p>
                  Thanks for updating your info, let us know if you change your
                  mind. Open Enrollment ends December 7!
                </p>
              )} */}
            </Form.Group>
            <Form.Group>
              <Form.Check
                className=" no-class"
                type="radio"
                label="No, I changed my mind"
                name="check_my_medications"
                id="formHorizontalRadios11 No"
                value={1}
                onChange={(e) => {
                  captureChange(e);
                  setDialog5(true);
                  setDialog2(false);
                }}
                checked={step1State.check_my_medications === 1}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </Form>,
    <Form key={11} onSubmit={(event) => event.preventDefault()}>
      <div className="row">
        <div className="col-12">
          <label>
            If yes, do you want your Part D premiums paid automatically from
            your benefits? This is only possible if you are already taking
            Social Security.
          </label>
        </div>
      </div>
      <div className="row step3 step8">
        <Form.Group className="col-md-6 col-xl-4">
          <Form.Check
            type="radio"
            label="Yes, Simpler that way"
            name="social_benefits_paid_automatically"
            id="formHorizontalRadios8"
            value={1}
            onChange={(e) => captureChange(e)}
            checked={step1State.social_benefits_paid_automatically === 1}
          />
        </Form.Group>
        <Form.Group className="col-md-6 col-xl-4 no-class">
          <Form.Check
            type="radio"
            label="No, I prefer to pay it myself"
            name="social_benefits_paid_automatically"
            id="formHorizontalRadios9"
            value={0}
            onChange={(e) => captureChange(e)}
            checked={step1State.social_benefits_paid_automatically === 0}
          />
        </Form.Group>
      </div>
    </Form>,
    <div className="row" key={12}>
      <div className="col-md-12">
        <p
          style={{
            fontWeight: "bold",
            fontSize: 20,
            color: "rgba(2, 33, 68, 0.8)",
          }}
        >
          Are you ready to enter your medications?
        </p>
      </div>
      <div className="col-md-12">
        <Button
          key={13}
          className="btn btn-primary"
          onClick={() => {
            if (missingFields.length === 0) {
              window.scrollTo(0, 0);
              update_profile(0);
            } else {
              setError(true);
            }
          }}
        >
          Yes, let's do it now!
        </Button>
      </div>
      <div className="col-md-12">
        <Button
          type="button"
          variant="info"
          style={{ marginTop: 10, padding: "8px 18px", borderRadius: 6 }}
          onClick={() => {
            if (missingFields.length === 0) {
              update_profile(1);
              window.scrollTo(0, 0);
              props.history.push({
                pathname: "/success",
                state: { endMessage: true },
              });
            } else {
              setError(true);
            }
          }}
        >
          I'll need to think about it!
        </Button>
      </div>
      {isLoading && <CustomSpinner animation={"border"} />}
    </div>,
  ];

  return (
    <div className="main-wrapper">
      <div className="row heading-wrapper">
        <div className="col-12">
          <h2 className="main-heading">Client Profile Review</h2>
        </div>
      </div>
      <div className="row">
        <div className="section-top col-12">
          <Toast
            onClose={() => setError(false)}
            show={error}
            animation={true}
            style={{
              position: 'fixed',
              top: 20,
              right: 20,
              minWidth: '300px',
            }}
          >
            <Toast.Header>
              <strong className="mr-auto">Missing Fields</strong>
              {/* <small>messages</small> */}
              <img
                src={imageCross}
                onClick={() => setError(false)}
                alt="cross"
                data-dismiss="toast"
              />
            </Toast.Header>
            {missingFields.map((message, index) => {
              return <Toast.Body key={index}>{message}</Toast.Body>;
            })}
          </Toast>
          {step1Array}
        </div>
      </div>
    </div>
  );
};

export default Step1;
