import * as actionTypes from '../actions/constant/actionTypes';

const initialState = {
    isLoading: false,
    failed: false,
    mg_range: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.SERVICE_REQUEST:
            return {...state, ...action.payload};
        case actionTypes.SERVICE_SUCCESS:
            return {...state, ...action.payload};
        case actionTypes.SERVICE_ERROR:
            return {...state, ...action.payload};
        case actionTypes.MEDICINE_LIST:
            return {...state, medicine_list: action.medicine_list};
        case actionTypes.MG_RANGE:
            return {...state, mg_range: action.mg_range};
        default:
            return state;
    }
}

