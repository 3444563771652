import React from 'react';
import './App.css';
import './scss/style.scss';
import './scss/icomoons.scss';
import Root from './components/Root/Root';

function App() {
  return (
    <div className="App">
        <Root />
    </div>
  );
}

export default App;
