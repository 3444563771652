import Home from "../../Home/Home"
import Login from "../../Login/Login"
import Flow from "../../Flow/Flow"
import Success from "../../TextOnly/Success"
import Profile from "../../Home/Profile"

const publicRoutes = [
    {
        path: '/login',
        exact: true,
        component: Login,
    },
    
]

const privateRoutes = [
    {
        path: '/',
        exact: true,
        component: Flow,
    },
    {
        path: '/profile-build',
        exact: true,
        component: Flow,
    },
    {
        path: '/success',
        exact: true,
        component: Success,
    },
    {
        path: '/home',
        exact: false,
        component: Home,
    },
]

const privateHomeRoutes = [
    {
        path: '/home/profile',
        exact: true,
        component: Profile,
    },
]

export { publicRoutes, privateRoutes, privateHomeRoutes }