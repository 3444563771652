import React from 'react'
import { Spinner } from 'react-bootstrap'

const CustomSpinner = props => {
    return (
            <Spinner 
                    animation={props.animation} 
                    variant="primary" 
                    style={{
                        width: '2rem', 
                        height: '2rem',
                        marginLeft: '1rem'
                    }}
                />
    )
}

export default CustomSpinner