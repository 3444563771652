module.exports = {
    NEARME_BUSINESS: 'business/unauth/nearme',
    BUSINESS_NATURE: 'business/unauth/business-natures',
    BUSINESS_DEALS: 'product/unauth/business/deals',
    BUSINESS_CATEGORIES: 'category/unauth/consumer/',
    BUSINESS_CATEGORY_PRODUCTS: 'product/unauth/business/category/products',
    USER_ADDRESS: 'user/addresses/',
    DELIVERY_CHARGES: 'delivery/charges',
    MY_ORDERS: 'order/consumer',
    ORDER_DETAILS: 'order/add',
    REMOVE_USER_ADDRESS: 'user/remove/address/',
    SEARCH_SPECIFIC_BUSINESS_PRODUCT: 'search/unauth/specific/business/product',
    SEARCH_NEARME_BUSINESS_PRODUCT: 'search/unauth/product',
    USER_SESSION: '/api/users/login',
    GET_STATES: '/api/data/getStates?country_id=',
    GET_CITIES: '/api/data/getCities?state_id=',
    SEARCH_MEDICINES: '/api/client/meds/searchMedicine?search=',
    ADD_MEDICINES: '/api/client/meds/addUpdateUserMedicines',
    GET_MEDICINES: '/api/client/meds/listUserMedicines',
    DEL_MEDICINE: '/api/client/meds/deleteUserMedicine/',
    UPDATE_PROFILE: '/api/client/users',
    STRIPE_SESSION_ID: '/api/client/payment/getStripeSession',
    MG_RANGE: '/api/client/meds/getMgRange?medicine_name=',
};
