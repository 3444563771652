export const SERVICE_REQUEST = 'SERVICE_REQUEST';
export const SERVICE_SUCCESS = 'SERVICE_SUCCESS';
export const SERVICE_ERROR = 'SERVICE_ERROR';
export const USER_SESSION  = 'USER_SESSION';
export const USER_ADDRESS = 'USER_ADDRESS';
export const MAINTAIN_PROFILE = 'MAINTAIN_PROFILE';
export const ADD_MAINTAIN_PROFILE = 'ADD_MAINTAIN_PROFILE';
export const GET_CITIES = 'GET_CITIES';
export const GET_STATES = 'GET_STATES';
export const SEARCH_MEDICINES = 'SEARCH_MEDICINES';
export const EMPTY_SEARCH_MEDICINES = 'EMPTY_SEARCH_MEDICINES';
export const MEDICINE_LIST = 'MEDICINE_LIST';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const REQUIRE_LOGIN = 'REQUIRE_LOGIN';
export const MG_RANGE = 'MG_RANGE';