import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Step1 from './Step1';
import Step3 from './Step3';
import Step4 from './Step4';
import images from '../../images/logo.png';
import imageTick from '../../images/tick.svg';
// import PaymentDialog from '../PaymentDialog/PaymentDialog';
import moment from 'moment';
import * as actions from '../../redux/actions';
import { Button, Nav, Navbar } from 'react-bootstrap';

const Flow = (props) => {
  const dispatch = useDispatch();
  const [section, setSection] = useState(1);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const { userSession } = useSelector((state) => state.users);

  const checkPaymentPlanStatus = useCallback(() => {
    let startTimeMoment = moment(
      userSession.last_payment_date ?? new Date()
    ).diff(moment(new Date()));
    if (
      userSession.last_payment_status &&
      moment.duration(startTimeMoment).years() <= -1
    ) {
      setShowPaymentModal(true);
    }
  }, [userSession.last_payment_date, userSession.last_payment_status]);

  useEffect(() => {
    checkPaymentPlanStatus();
  }, [checkPaymentPlanStatus]);

  return (
    <>
      {/* {true && (
        <PaymentDialog
          showPaymentModal={showPaymentModal}
          setShowPaymentModal={setShowPaymentModal}
        />
      )} */}
      
      <div className="wrapper top-banner" style={{paddingTop:"30px"}}>

        <div>
      <Button
        style={{ marginTop: 10, marginRight: 10, float: 'right' }}
        type="button"
        variant="info"
        onClick={() => dispatch(actions.logoutUser(props.history))}
      >
        Logout
      </Button>
      </div>
      
        <div className="container inner-wrapper">
          <div className="row">
            <div className="col-md-6">
              {' '}
              <img src={images} width="170" alt="logo" />
            </div>
            <div className="col-md-6">
              <ul className="stepper d-flex">
                <li className={section < 2 ? 'active' : 'success'}>
                  {section === 1 ? 1 : <img src={imageTick} alt="delete" />}
                </li>
                <li
                  className={
                    section === 2 ? 'active' : section > 2 ? 'success' : ''
                  }
                >
                  {section === 2 || section < 2 ? (
                    2
                  ) : (
                    <img src={imageTick} alt="delete" />
                  )}
                </li>
                <li
                  className={
                    section === 3 ? 'active' : section > 3 ? 'success' : ''
                  }
                >
                  {section === 3 || section < 3 ? (
                    3
                  ) : (
                    <img src={imageTick} alt="delete" />
                  )}
                </li>
              </ul>
            </div>
          </div>
          {section === 1 && (
            <Step1
              section={section}
              setSection={setSection}
              history={props.history}
              //   setStep1State={setStep1State}
              //   step1State={step1State}
            />
          )}
          {section === 2 && <Step3 section={section} setSection={setSection} />}
          {section === 3 && (
            <Step4 history={props.history} setSection={setSection} />
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(Flow);
