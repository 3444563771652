import React from 'react';
import '../../App.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { withRouter, Route } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import images from '../../images/logo.png';

import { privateHomeRoutes } from '../Root/Routes/Routes';

const Home = (props) => {
  return (
    <div className="wrapper">
      <div className="container inner-wrapper">
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="#home">
            <img src={images} width="170" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/home/profile">Profile</Nav.Link>
              <Nav.Link href="#link">Drugs</Nav.Link>
              <Nav.Link onClick={() => props.logoutUser(props.history)}>
                Log out
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {/* <Switch> */}
        {privateHomeRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              render={() => <route.component />}
            />
          );
        })}
        {/* </Switch> */}
        {/* <Profile /> */}
      </div>
    </div>
    // <header className="App-header">
    // <img src={logo} className="App-logo" alt="logo" />
    // <p>
    //   {props.userSession?.data?.user?.name}
    // </p>
    // <a
    //   className="App-link"
    //   href="https://reactjs.org"
    //   target="_blank"
    //   rel="noopener noreferrer"
    // >
    //   {props.userAddresses?.data?.user?.office}
    // </a>
    // <Link to='/profile-build'>Profile</Link>
    // </header>
  );
};

function mapStateToProps(state) {
  const { userAddresses, userSession } = state.users;
  return {
    userAddresses,
    userSession,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserSession: bindActionCreators(actions.getUserSession, dispatch),
    logoutUser: bindActionCreators(actions.logoutUser, dispatch),
    getUserAddresses: bindActionCreators(actions.getUserAddresses, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
