import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../../../redux/actions';

const PublicRoute = props => {
    const {path, exact} = props
    return (
        <Route
            exact={exact}
            path={path}
            render={prop => {
                return (
                    props.isAuthenticated
                    ?
                        <Redirect to="/" />
                    :
                        <props.component {...prop} />

                )
            }}
        />
    )
}

function mapStateToProps(state) {
    const {
        isAuthenticated,
    } = state.users;
    return {
        isAuthenticated
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUserSession: bindActionCreators(actions.getUserSession, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
