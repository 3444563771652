import axios from 'axios';
import { push } from 'react-router-redux';
import * as actionTypes from './constant/actionTypes';
import { store } from '../../stores/configureStore';
import { persistor } from '../../stores/configureStore';

const api = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
});
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      persistor.purge();
      persistor.pause();
      window.location.replace('/login');
    } else {
      window.location.replace('/error');
    }
  }
);
export default api;

function request() {
  return {
    type: actionTypes.SERVICE_REQUEST,
    payload: { isLoading: true, failed: false },
  };
}

function success() {
  return {
    type: actionTypes.SERVICE_SUCCESS,
    payload: { isLoading: false, failed: false },
  };
}

function failure() {
  return {
    type: actionTypes.SERVICE_ERROR,
    payload: { isLoading: false, failed: true },
  };
}

function header() {
  const users = store.getState().users?.userSession;
  return users?.token ? `Bearer ${users.token}` : '';
}

export function get(url, params, target, redirect) {
  if (header() !== '') {
    api.defaults.headers.common = {
      Authorization: header(),
      'Content-Type': 'application/json',
    };
  }
  return (dispatch) => {
    dispatch(request());
    return api
      .get(url + params)
      .then((response) => {
        dispatch(target(response.data));
        dispatch(success());
        if (redirect !== null) {
          dispatch(push(redirect));
        }
      })
      .catch((error) => {
        dispatch(failure());
        throw error;
      });
  };
}

export function del(url, params, target, redirect) {
  if (header() !== '') {
    api.defaults.headers.common = {
      Authorization: header(),
      'Content-Type': 'application/json',
    };
  }
  return (dispatch) => {
    dispatch(request());
    return api
      .delete(url + params)
      .then((response) => {
        dispatch(target(response.data));
        dispatch(success());
        if (redirect !== null) {
          dispatch(push(redirect));
        }
      })
      .catch((error) => {
        dispatch(failure());
        throw error;
      });
  };
}

export function post(url, json, target, redirect, history) {
  if (header() !== '') {
    api.defaults.headers.common = {
      Authorization: header(),
      'Content-Type': 'application/json',
    };
  }
  return (dispatch) => {
    dispatch(request());
    return api
      .post(url, json)
      .then((response) => {
        dispatch(target(response.data));
        dispatch(success());
        if (redirect !== null && response.data.status) {
          history.push(redirect);
        }
        if (response.data.status) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(failure());
        throw error;
      });
  };
}

export function put(url, json, target, redirect, history) {
  if (header() !== '') {
    api.defaults.headers.common = {
      Authorization: header(),
      'Content-Type': 'application/json',
    };
  }
  return (dispatch) => {
    dispatch(request());
    return api
      .put(url, json)
      .then((response) => {
        if (target !== null) {
          dispatch(target(response.data));
        }
        dispatch(success());
        if (redirect !== null && response.data.status) {
          history.push(redirect);
        }
        if (response.data.status) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(failure());
        throw error;
      });
  };
}
