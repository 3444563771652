import React, { useState, useEffect, useRef, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { Modal, Button, Toast, FormControl } from 'react-bootstrap';
import imageTick from '../../images/tick.svg';
import imageDelete from '../../images/delete.svg';
import imageEdit from '../../images/editMedicine.svg';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import { orderBy }from 'lodash';

const medic_key_arr = [
  'medicine_id',
  'require_brand_only',
  'how_often_take',
  'how_often_refill',
  'medicine_name',
  'mg_range',
  'comments',
];
const verify_medic_key_arr = [
  'medicine_id',
  'how_often_take',
  'how_often_refill',
  'mg_range',
  'medicine_name',
];
const PASSING_FIELD_LENGTH = 4;

const Step3 = (props) => {
  const { medicine_list, isLoading, mg_range } = useSelector(
    (state) => state.service
  );
let sortData=[]
// let sortMedicineByName = orderBy(medicine_list,['medicine.name'] , ['asc']);
if(Array.isArray(medicine_list)){
  if(medicine_list.length>0){
    const companyNameSorter = v => v.medicine?v.medicine.name.toLowerCase():[]
    sortData = orderBy(medicine_list, [companyNameSorter], ['asc']);
  }
  
}

  const { maintain_profile } = useSelector((state) => state.users);
  const [addMedicineForMe, setAddMedicineForMe] = useState(
    maintain_profile.add_medicine_for_me ?? false
  );
  const [dialog, setDialog] = useState(false)
  const [borderHighLight, setBorderHighLight] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [updateMedicineId, setUpdateMedicineId] = useState(null)
  const [chosenMedicine, setChosenMedicine] = useState(() => {
    const temp = {};
    medic_key_arr.forEach((key) => {
      temp[key] = '';
    });
    return temp;
  });

  const [allowAdd, setAllowAdd] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showMg, setShowMg] = useState(false);
  const [inputValueOtherRefillDrug, setInputValueOtherRefillDrug] = useState(
    false
  );
  const [inputValueOtherTakeDrug, setInputValueOtherTakeDrug] = useState(false);
  const [otherTakeDrug, setOtherTakeDrug] = useState(
    chosenMedicine.how_often_take !== '' &&
      chosenMedicine.how_often_take !== '1x' &&
      chosenMedicine.how_often_take !== '2x' &&
      chosenMedicine.how_often_take !== '3x' &&
      chosenMedicine.how_often_take !== 'As Needed'
  );
  const [otherRefillDrug, setOtherRefillDrug] = useState(
    chosenMedicine.how_often_take !== '' &&
      chosenMedicine.how_often_take !== 'Every Month' &&
      chosenMedicine.how_often_take !== 'Every Two Months' &&
      chosenMedicine.how_often_take !== 'Every Three Months'&&
      chosenMedicine.how_often_take !== 'As Needed'
  );
  const input = useRef(null);
  const commentsRef = useRef(null);
  const mg = useRef(null);
  const dispatch = useDispatch();

  const { medicines } = useSelector((state) => state.users);

  const showSuggestions = (event) => {
    dispatch(actions.search_medicines(event.target.value));
  };

  const captureChange = (event, boolean = false) => {
    setChosenMedicine({
      ...chosenMedicine,
      [event.target.name]: boolean ? +event.target.value : event.target.value,
    });
  };

  const captureChangeMedicine = (event) => {
    mg.current.value = '';
    setChosenMedicine({
      how_often_take: chosenMedicine.how_often_take,
      how_often_refill: chosenMedicine.how_often_refill,
      medicine_id: '',
      [event.target.name]: event.target.value,
    });
  };

  const set = () => {
    setTimeout(function () {
      setShowList(false);
    }, 200);
  };
  const set2 = () => {
    setTimeout(function () {
      setShowMg(false);
    }, 200);
  };

  const remove_suggestion = useCallback(() => {
    dispatch(actions.search_medicines(''));
  }, [dispatch]);

  const verify_fields = useCallback(() => {
    let fields = 0;
    verify_medic_key_arr.forEach((key) => {
      if (chosenMedicine[key] !== undefined && chosenMedicine[key] !== '') {
        fields += 1;
      }
    });
    if (fields === PASSING_FIELD_LENGTH) {
      return true;
    } else {
      return false;
    }
  }, [chosenMedicine]);

  const add = () => {
    setIsEditMode(false)
    const medicine_payload = [];
    const medicine = {};
    
    medic_key_arr.forEach((key) => {
      if (chosenMedicine[key] === '') {
        return;
      } else {
        medicine[key] = chosenMedicine[key];
      }
    });
    if ('medicine_id' in medicine) {
      delete medicine.medicine_name;
    }
    if ('mg_range' in medicine && medicine.mg_range) {
      medicine['medicine_name'] = chosenMedicine['medicine_name'];
    }
   
      if(updateMedicineId && isEditMode){
        medicine.user_medicine_id = updateMedicineId
      }
    medicine_payload.push(medicine);
    dispatch(actions.add_medicine({ medicines: medicine_payload }));
  };

  const reset = () => {
    dispatch(actions.resetMgRange());
    if (!!input.current) {
      input.current.value = '';
      commentsRef.current.value = '';
      mg.current.value = '';
    }

    setOtherRefillDrug(false);
    setOtherTakeDrug(false);
    setChosenMedicine({
    });
  };
  const resetFields = () => {
  reset();
  setIsEditMode(false)
}
  const delete_medicine = (id) => {
    dispatch(actions.delete_medicine(id));
  };

  const updateMedicine = (medicineDetail,path) => {
    setTimeout(() => {
      setBorderHighLight(false)
     },[3000])

    setBorderHighLight(true )
    document.getElementById(path).scrollIntoView({behavior: "smooth", block: "center"});
    // window.scrollTo(0, 500);
    setUpdateMedicineId(medicineDetail.id)
    setIsEditMode(true)
    if(medicineDetail && medicineDetail.medicine){
      input.current.value = medicineDetail.medicine.name;
      commentsRef.current.value = medicineDetail.comments;
      mg.current.value = medicineDetail.medicine.mg_range;
    }
    if(medicineDetail.how_often_take !=='As Needed' && medicineDetail.how_often_take !=='1x' && medicineDetail.how_often_take !=='2x' && medicineDetail.how_often_take !=='3x'){
      setOtherTakeDrug(true)
    }else{
      setOtherTakeDrug(false)
      setInputValueOtherTakeDrug(false)
     
    }
    if(medicineDetail.how_often_refill!=='As Needed' && medicineDetail.how_often_refill!=='Every Month' && medicineDetail.how_often_refill!=='Every Two Months' && medicineDetail.how_often_refill!=='Every Three Months'){
      setOtherRefillDrug(true);
    }else{
      setInputValueOtherRefillDrug(false);
      setOtherRefillDrug(false);
    }
    setChosenMedicine({
      ...chosenMedicine,
      how_often_take:medicineDetail.how_often_take ,
      how_often_refill:medicineDetail.how_often_refill,
      medicine_name:medicineDetail.medicine.name,
      require_brand_only:medicineDetail.require_brand_only,
      medicine_id:medicineDetail.medicine_id,
    });
  };

  useEffect(() => {
    dispatch(actions.populate_previous_medicines());
  }, [dispatch]);

  useEffect(() => {
    remove_suggestion();
  }, [remove_suggestion]);

  useEffect(() => {
    setAllowAdd(verify_fields);
  }, [verify_fields]);

  useEffect(() => {
    setChosenMedicine(() => {
      const temp = {};
      medic_key_arr.forEach((key) => {
        temp[key] = '';
      });
      return temp;
    });
    reset();
  }, [medicine_list]);

  useEffect(() => {
    if (
      inputValueOtherTakeDrug === false &&
      (chosenMedicine.how_often_take === '1x' ||
        chosenMedicine.how_often_take === '2x' ||
        chosenMedicine.how_often_take === '3x'||
        chosenMedicine.how_often_take === 'As Needed' )
    ) {
      setOtherTakeDrug(false);
    }
  }, [chosenMedicine.how_often_take]);

  useEffect(() => {
    if (
      inputValueOtherRefillDrug === false &&
      (chosenMedicine.how_often_refill === 'Every Month' ||
        chosenMedicine.how_often_refill === 'Every Two Months' ||
        chosenMedicine.how_often_refill === 'Every Three Months'||
        chosenMedicine.how_often_refill === 'As Needed'
        )
    ) {
      setOtherRefillDrug(false);
    }
  }, [chosenMedicine.how_often_refill]);

  useEffect(() => {
    dispatch(actions.get_mg_range(chosenMedicine.medicine_name));
  }, [chosenMedicine.medicine_name, dispatch]);

  return (
    <div className="main-wrapper">
      <div className="row heading-wrapper">
        <div className="col-12">
          <h1 className="main-heading">
            Add Prescriptions
            <Button
              style={{ float: 'right' }}
              onClick={() => {
                window.scrollTo(0, 0);
                props.setSection(1);
              }}
            >
              Back
            </Button>
          </h1>
        </div>
      </div>

      <div>
        <div className="row">
          <div className="col-12">
            <div className="step2-details">
              <h3 className="hmm-summary-heading">
                This is where you will add and update your medications.
              </h3>
              <h3 className="hmm-summary-heading">
                Only include it if it <em> must</em> be filled by a pharmacist.
              </h3>
              <h3 className="hmm-summary-heading">
                <em> Do not</em> include over-the-counter medications.
              </h3>

              <h2
                style={{
                  fontWeight: 'bold',
                  color: '#1f3763',
                  textDecoration: 'underline',
                }}
              >
                PLEASE READ CAREFULLY!
              </h2>
              <h4>
                Our research and recommendation can only be as accurate as the
                information you give us.
              </h4>
              <h5 style={{ fontWeight: 'bold' }}> Instructions:</h5>
              <ol>
                <li>
                  Type in the name of the drug{' '}
                  <strong
                    style={{ textDecoration: 'underline', fontSize: '1.3rem' }}
                  >
                    as it appears on the prescription bottle.
                  </strong>{' '}
                  We have added the most common prescriptions with options for
                  you to choose from.
                </li>
                <li>
                  If your <strong>Drug OR Dosage</strong> does not appear as an
                  option, just type it into the box.
                </li>
                <li>Press Add </li>
              </ol>
              <p style={{ fontSize: '1.2rem' }}>
                If you choose not to do this, you can mail/email us a list of
                them and we will enter them for you for an extra fee of $50 per
                person.
              </p>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  style={{ zoom: 1.3 }}
                  defaultChecked={addMedicineForMe}
                  type="checkbox"
                  label="I want you to enter them for me. Please contact me."
                  onClick={() => {
                    setAddMedicineForMe(!addMedicineForMe);
                    dispatch(actions.add_medicine_for_me(!addMedicineForMe));
                  }}
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="">
          {!addMedicineForMe ? (
            <React.Fragment>
              {' '}
              <div id="medicineDiv" style={{padding:'10px',borderRadius:'10px', backgroundColor:borderHighLight ? "#B1F7D8":null }}>
              <div className="row section-top medicine-container-name">
                <div className="col-12">
                  <label>Add Medicine as it appears on label</label>
                </div>
                <Form.Group className="col-md-5 medicine-container-name-input">
                  <input
                    ref={input}
                    onChange={(e) => {
                      captureChangeMedicine(e);
                      showSuggestions(e);
                    }}
                    type="text"
                    id="medicine"
                    name="medicine_name"
                    placeholder="Medicine"
                    autoComplete="off"
                    onFocus={() => setShowList(true)}
                    onBlur={() => set()}
                  />
                  <div
                    className="dropdown-wrapper"
                    style={{ overFlow: 'scroll !important' }}
                  >
                    {showList &&
                      medicines?.map((medicine) => {
                        return (
                          <div key={medicine.id} className="dropdown">
                            <div
                              className="dropdown-item"
                              onClick={(e) => {
                                input.current.value = medicine.name;
                                remove_suggestion();
                                const temp = {
                                  medicine_name: medicine.name,
                                  require_brand_only:
                                    chosenMedicine.require_brand_only,
                                  how_often_take: chosenMedicine.how_often_take,
                                  how_often_refill:
                                    chosenMedicine.how_often_refill,
                                };
                                setChosenMedicine(temp);
                                if (
                                  medicine.name !== medicine.generic_name ||
                                  medicine.generic_name === ''
                                ) {
                                  setDialog(true);
                                }
                              }}
                            >
                              {medicine.name}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Form.Group>
                <div className="col-md-3 medicine-container-dosage">
                  <Form.Group
                    className="medicine-container-dosage-group"
                    onBlur={() => set2()}
                  >
                    <FormControl
                      style={{ height: '100%', color: 'black' }}
                      onChange={(e) => {
                        chosenMedicine?.medicine_id &&
                          delete chosenMedicine.medicine_id;
                        captureChange(e);
                      }}
                      type="select"
                      name="mg_range"
                      autoComplete="off"
                      placeholder="Dosage"
                      ref={mg}
                      onFocus={() => setShowMg(true)}
                    />

                    <div className="dropdown-wrapper" hidden={!showMg}>
                      {mg_range?.length > 0 &&
                        mg_range
                          ?.filter((med_mg_obj) => {
                            if (med_mg_obj.mg_range.match(mg.current?.value)) {
                              return true;
                            } else {
                              return false;
                            }
                          })
                          .map((med_mg) => {
                            return (
                              <div key={med_mg.id} className="dropdown">
                                <div
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    mg.current.value = med_mg.mg_range;
                                    chosenMedicine?.mg_range &&
                                      delete chosenMedicine.mg_range;
                                    // remove_suggestion()
                                    const temp = {
                                      ...chosenMedicine,
                                      medicine_id: med_mg.id,
                                      require_brand_only:
                                        chosenMedicine.require_brand_only,
                                      how_often_take:
                                        chosenMedicine.how_often_take,
                                      how_often_refill:
                                        chosenMedicine.how_often_refill,
                                    };
                                    setChosenMedicine(temp);
                                  }}
                                >
                                  {med_mg.mg_range}
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </Form.Group>
                </div>
                <Form.Group className="col-md-4 medicine-container-name-input">
                  <input
                    ref={commentsRef}
                    onChange={(e) => {
                      captureChange(e);
                    }}
                    type="text"
                    id="comments"
                    name="comments"
                    placeholder="Comments"
                    autoComplete="off"
                    maxLength={200}
                  />
                </Form.Group>
              </div>
              <Modal show={dialog}>
                <Modal.Body>
                  <Form className="popup-form">
                    <h5>
                      "A generic drug,{' '}
                      <strong>{chosenMedicine?.generic_name}</strong> is
                      available and can offer additional savings. Do you require
                      it to be brand only?"
                    </h5>
                    <Form.Group className="yes-class">
                      <Form.Check
                        type="radio"
                        label="Yes"
                        name="require_brand_only"
                        id="formHorizontalRadios11 Yes"
                        onChange={(e) => captureChange(e, true)}
                        value={1}
                        checked={chosenMedicine.require_brand_only === 1}
                      />
                    </Form.Group>
                    <Form.Group className="no-class">
                      <Form.Check
                        style={{ fontWeight: 'bold' }}
                        type="radio"
                        label="No"
                        name="require_brand_only"
                        id="formHorizontalRadios11 No"
                        onChange={(e) => captureChange(e, true)}
                        value={0}
                        checked={chosenMedicine.require_brand_only === 0}
                      />
                    </Form.Group>
                    <button
                      className="btn btn-primary"
                      disabled={
                        !(
                          chosenMedicine.require_brand_only === 0 ||
                          chosenMedicine.require_brand_only === 1
                        )
                      }
                      type="button"
                      onClick={() => setDialog(false)}
                    >
                      <i className="tick">
                        <img src={imageTick} alt="delete" />
                      </i>{' '}
                      Ok
                    </button>
                  </Form>
                </Modal.Body>
              </Modal>
              <div className="row add-drugs-info">
                <Form
                  className="col-md-12 section-top"
                  key={'take drug'}
                  onSubmit={(event) => event.preventDefault()}
                >
                  <label className="drug-intro">
                    How many times a day do you take it?
                  </label>
                  <div className="row">
                    <Form.Group className="col-md-2">
                      <Form.Check
                        type="radio"
                        label="1x"
                        name="how_often_take"
                        id="formHorizontalRadios12 daily"
                        value={'1x'}
                        onChange={(e) => {
                          captureChange(e);
                          setInputValueOtherTakeDrug(false);
                        }}
                        checked={
                          !inputValueOtherTakeDrug &&
                          chosenMedicine.how_often_take === '1x'
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-md-2">
                      <Form.Check
                        type="radio"
                        label="2x"
                        name="how_often_take"
                        id="formHorizontalRadios12 as_needed"
                        value={'2x'}
                        onChange={(e) => {
                          captureChange(e);
                          setInputValueOtherTakeDrug(false);
                        }}
                        checked={
                          !inputValueOtherTakeDrug &&
                          chosenMedicine.how_often_take === '2x'
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-md-2">
                      <Form.Check
                        type="radio"
                        label="3x"
                        name="how_often_take"
                        id="formHorizontalRadios12 3x"
                        value={'3x'}
                        onChange={(e) => {
                          captureChange(e);
                          setInputValueOtherTakeDrug(false);
                        }}
                        checked={
                          !inputValueOtherTakeDrug &&
                          chosenMedicine.how_often_take === '3x'
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-md-3">
                      <Form.Check
                        type="radio"
                        label="As Needed"
                        name="how_often_take"
                        id="formHorizontalRadios12 As Needed"
                        value={'As Needed'}
                        onChange={(e) => {
                          captureChange(e);
                          setInputValueOtherTakeDrug(false);
                        }}
                        checked={
                          !inputValueOtherTakeDrug &&
                          chosenMedicine.how_often_take === 'As Needed'
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-md-3">
                      <Form.Check
                        type="radio"
                        label="Other"
                        name="how_often_take"
                        id="formHorizontalRadios12 otherTakeDrug"
                        value={'Other'}
                        onChange={(e) => {
                          setChosenMedicine({
                            ...chosenMedicine,
                            how_often_take: '',
                          });
                          setInputValueOtherTakeDrug(true);
                          setOtherTakeDrug(true);
                        }}
                        checked={otherTakeDrug}
                      />
                    </Form.Group>
                  </div>
                  <div className="row">
                    {otherTakeDrug && (
                      <Form.Group className="col-md-5">
                        <input
                          onChange={(e) => {
                            captureChange(e);
                          }}
                          type="text"
                          id="how_often_take_other"
                          name="how_often_take"
                          value={chosenMedicine.how_often_take ?? ''}
                        />
                      </Form.Group>
                    )}
                  </div>
                </Form>
                <Form
                  onSubmit={(event) => event.preventDefault()}
                  className="col-md-12 section-top"
                  key={'refill'}
                >
                  <label className="drug-intro">
                    How often do you refill it?
                  </label>
                  <div className="row">
                    <Form.Group className="col-md-2">
                      <Form.Check
                        type="radio"
                        label="Every Month"
                        name="how_often_refill"
                        id="formHorizontalRadios13 every_month"
                        value={'Every Month'}
                        onChange={(e) => {
                          captureChange(e);
                          setInputValueOtherRefillDrug(false);
                        }}
                        checked={
                          !inputValueOtherRefillDrug &&
                          chosenMedicine.how_often_refill === 'Every Month'
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-md-3">
                      <Form.Check
                        type="radio"
                        label="Every Two Months"
                        name="how_often_refill"
                        id="formHorizontalRadios13 every_2_month"
                        value={'Every Two Months'}
                        onChange={(e) => {
                          captureChange(e);
                          setInputValueOtherRefillDrug(false);
                        }}
                        checked={
                          !inputValueOtherRefillDrug &&
                          chosenMedicine.how_often_refill === 'Every Two Months'
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-md-3">
                      <Form.Check
                        type="radio"
                        label="Every Three Months"
                        name="how_often_refill"
                        id="formHorizontalRadios13 every_3_month"
                        value={'Every Three Months'}
                        onChange={(e) => {
                          captureChange(e);
                          setInputValueOtherRefillDrug(false);
                        }}
                        checked={
                          !inputValueOtherRefillDrug &&
                          chosenMedicine.how_often_refill ===
                            'Every Three Months'
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-md-2">
                      <Form.Check
                        type="radio"
                        label="As Needed"
                        name="how_often_refill"
                        id="formHorizontalRadios13 As Needed "
                        value={'As Needed'}
                        onChange={(e) => {
                          captureChange(e);
                          setInputValueOtherRefillDrug(false);
                        }}
                        checked={
                          !inputValueOtherRefillDrug &&
                          chosenMedicine.how_often_refill ===
                            'As Needed'
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-md-2">
                      <Form.Check
                        type="radio"
                        label="Other"
                        name="how_often_refill"
                        id="formHorizontalRadios13 other"
                        value={'Other'}
                        onChange={(e) => {
                          setChosenMedicine({
                            ...chosenMedicine,
                            how_often_refill: '',
                          });
                          setInputValueOtherRefillDrug(true);
                          setOtherRefillDrug(true);
                        }}
                        checked={otherRefillDrug}
                      />
                    </Form.Group>
                  </div>
                  <div className="row">
                    {otherRefillDrug && (
                      <Form.Group className="col-md-5">
                        <input
                          onChange={(e) => {
                            captureChange(e);
                          }}
                          type="text"
                          id="how_often_take_refill_other"
                          name="how_often_refill"
                          value={chosenMedicine.how_often_refill ?? ''}
                        />
                      </Form.Group>
                    )}
                  </div>
                </Form>
                <div className="col-12 spacer">
                  {isEditMode?
                  <>        
                  <Button
                  disabled={!allowAdd}
                  className="btn btn-primary"
                  type="button"
                  onClick={() => add()}
                  >
                    <i className="tick">
                      <img src={imageTick} alt="delete" />
                      </i>{' '}
                    Update
                    </Button>
                    <a style={{marginLeft:"10px"}}
                     href="#" onClick={()=>resetFields()}>Cancel</a>
                    </>
                    :
                    <Button
                    disabled={!allowAdd}
                    className="btn btn-primary"
                    type="button"
                    onClick={() => add()}
                  >
                    <i className="tick">
                      <img src={imageTick} alt="delete" />
                    </i>{' '}
                    Add
                  </Button>}
                  
                </div>
              </div>{' '}
              </div>
              </React.Fragment>
          ) : null}
          <h1 className="medical-list">My Medication List</h1>
          {/* {medicine_list?.length > 0 ? '' : <h6>Add Medicines</h6>} */}
          <div
            className=" summary-wrapper drugs-detail"
            style={{ marginBottom: '10px' }}
          >
            {sortData?.map((medicine, index) => {
              return (<>
              {medicine.medicine?
              
                <div key={index} className="row section-two-head">
                  
                 
                  <div className="col-6 col-md-2">
                    <h6 className="section-two-intro">
                      {medicine.medicine?medicine.medicine.name:""}
                    </h6>
                    <p className="section-two-intro small">
                      {medicine.medicine?medicine.medicine.mg_range:""}
                    </p>
                  </div>
                  <div className="col-6 col-md-2">
                    <p className="section-two-intro small">
                      {medicine.how_often_take}
                      {medicine.how_often_take === '1x' ||
                      medicine.how_often_take === '2x' ||
                      medicine.how_often_take === '3x' ||
                      medicine.how_often_take === 'As Needed'
                        ? ` Daily`
                        : ''}
                    </p>
                  </div>
                  <div className="col-6 col-md-2">
                    <p className="section-two-intro small">
                      {medicine.how_often_refill}
                    </p>
                    <p className="section-two-intro small">Refill</p>
                  </div>
                  <div className="col-6 col-md-4">
                    <p className="section-two-intro small">
                      {medicine?.comments}
                    </p>
                  </div>
                  <div className="col-6 col-md-2">
                    <i
                      className="edit"
                      onClick={() => updateMedicine(medicine,"medicineDiv")}
                    >
                      <img src={imageEdit} alt="Edit" />
                    </i>
                    <i
                      className="delete"
                      onClick={() => delete_medicine(medicine.id)}
                    >
                      <img src={imageDelete} alt="delete" />
                    </i>
                  </div>
            
                </div>:null
            }</>
              );
            })}
          </div>
          <div className="row last" style={{ marginBottom: '5rem' }}>
            <div className="col-12">
              <Button
                style={{ marginRight: 10, padding: '10px 18px' }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  props.setSection(1);
                }}
              >
                Back
              </Button>
              {(medicine_list?.length > 0 || addMedicineForMe) && (
                <>
                  {/* <br /> */}
                  <Button
                    className="proceed secondary"
                    disabled={isLoading}
                    type="button"
                    value="Proceed to Payment"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      let addMedicineForUser = {
                        add_medicine_for_me: addMedicineForMe,
                      };
                      props.setSection(3);
                      dispatch(
                        actions.update_profile_in_DB(addMedicineForUser)
                      );
                      // if (addMedicineForMe) {
                      //   dispatch(actions.add_medicine({ medicines: [] }));
                      // }
                    }}
                  >
                    Proceed to Payment
                  </Button>
                  <span>
                    You will have another chance to edit/review your list.
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <Toast
          show={isLoading}
          style={{
            position: 'fixed',
            top: 20,
            right: 20,
            minWidth: '150px',
          }}
        >
          <Toast.Body>
            <span>Updating</span>
            <CustomSpinner animation={'border'} />
          </Toast.Body>
        </Toast>
      </div>
    </div>
  );
};

export default Step3;
