import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import rootReducer from "../redux/reducers";
import { history } from "../utils/history";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
const logger = createLogger();
const router = routerMiddleware(history);

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  undefined,
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunk, router)
    : composeWithDevTools(applyMiddleware(thunk, router, logger))
);
export const persistor = persistStore(store);
