import * as actionTypes from '../actions/constant/actionTypes';

const initialState = {
    userSession: {
        name: 'Haad'
    },
    userAddresses: {
        office: 'ExpertsCloud'
    },
    isAuthenticated: null,
    states:[]
};

export default function (state = initialState, action) {
        switch (action.type) {
        case actionTypes.USER_SESSION:
            const {userSession, isAuthenticated, maintain_profile1} = action;
            return {...state, userSession, isAuthenticated, maintain_profile: maintain_profile1};
        case actionTypes.USER_ADDRESS:
            const {userAddresses} = action;
            if(Object.keys(userAddresses).length === 0){
                return {...state, userAddresses: []};
            }
            const {data} = userAddresses;
            return {...state, userAddresses: Object.assign([], data)}; 
        case actionTypes.MAINTAIN_PROFILE:
            const {maintain_profile} = action;
            return {...state, maintain_profile}
        case actionTypes.ADD_MAINTAIN_PROFILE:
            const prev_maintain_profile = action.maintain_profile;
            return {...state, maintain_profile: prev_maintain_profile}
        case actionTypes.GET_CITIES:
            return {...state, cities: action.cities}
        case actionTypes.GET_STATES:
            return {...state, states: action.states}
        case actionTypes.SEARCH_MEDICINES:
            return {...state, medicines: action.medicines}
        case actionTypes.EMPTY_SEARCH_MEDICINES:
            return {...state, medicines: action.medicines}
        case actionTypes.SET_SESSION_ID:
            return {...state, stripe_session: action.stripe_session}
        case actionTypes.REQUIRE_LOGIN:
            return {...state, isAuthenticated: false}
        default:
            return state;
    }
}

