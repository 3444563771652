import React, { useState, useEffect, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { Modal, Button, Toast } from 'react-bootstrap';
import imageCross from '../../images/crosss.svg';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
// import {key_array_state1} from './Flow'
import { profile_keys, profile_keys_messages } from '../../utils/fields';
import { withRouter } from 'react-router-dom';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import { store } from '../../stores/configureStore';

const Profile = (props) => {
  const [profile, setProfile] = useState(() => {
    const { maintain_profile } = store.getState().users;
    return maintain_profile;
  });
  const [edit, setEdit] = useState(false);
  const [dialog2, setDialog2] = useState(false);
  const [error, setError] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const { states, cities } = useSelector((state) => state.users);
  const { isLoading } = useSelector((state) => state.service);

  const dispatch = useDispatch();

  const captureChange = (event) => {
    if (event.target.name === 'phone_emergency_contact_relationship') {
      setProfile({
        ...profile,
        [event.target.name]: event.target.value,
      });
    } else {
      setProfile({
        ...profile,
        [event.target.name]:
          event.target.type === 'radio'
            ? +event.target.value
            : event.target.value,
      });
    }
  };

  const removeChange = () => {
    setProfile({
      ...profile,
      city_id: '',
    });
  };

  const hybridChange = async (event) => {
    setProfile({
      ...profile,
      [event.target.name]:
        event.target.type === 'radio'
          ? +event.target.value
          : event.target.value,
      city_id: '',
    });
  };

  const verify_fields = useCallback(() => {
    let temp = [];
    profile_keys.forEach((key, index) => {
      if (profile[key] !== '') {
        if (key === 'phone_emergency_contact_relationship') {
          if (profile.phone_emergency_contact_relationship === 'Other') {
            if (
              profile.contact_relationship_others !== '' &&
              profile.contact_relationship_others
            ) {
            } else {
              temp.push(profile_keys_messages[key].error);
            }
          }
        }
      } else {
        temp.push(profile_keys_messages[key].error);
      }
    });
    return temp;
  }, [profile]);

  const update_profile = () => {
    dispatch(actions.update_profile_in_DB(profile));
    dispatch(actions.profile(profile));
    // props.setSection(props.section + 1)
  };

  const apply_changes = () => {
    // () => missingFields.length === 0 ? update_profile() : setError(true)
    if (missingFields.length === 0) {
      update_profile();
      setEdit(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    setMissingFields(verify_fields());
  }, [verify_fields]);

  useEffect(() => {
    dispatch(actions.populate_cities(profile.state_id));
  }, [dispatch, profile.state_id]);

  const step1Array = [
    <Form className="row" key={1}>
      <Form.Group className="col-md-5">
        <label>Name</label>
        <input
          disabled={!edit}
          onChange={(e) => {
            captureChange(e);
          }}
          type="text"
          id="name"
          name="name"
          defaultValue={profile.name}
        />
      </Form.Group>
    </Form>,
    <Form className="row" key={2}>
      <Form.Group className="col-md-5">
        <label>Address</label>
        <input
          disabled={!edit}
          onChange={(e) => captureChange(e)}
          type="text"
          id="address"
          name="address"
          defaultValue={profile.address}
        />
      </Form.Group>
    </Form>,
    <Form className="d-flex row" key={3}>
      <Form.Group className="col-md-4 col-lg-3 arrow">
        <label>State</label>
        <select
          disabled={!edit}
          onChange={(e) =>
            e.target.value !== '' ? hybridChange(e) : removeChange(e)
          }
          type="select"
          id="state"
          name="state_id"
          value={profile.state_id}
        >
          <option value="">Select State</option>
          {states?.map((state) => {
            return (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            );
          })}
        </select>
      </Form.Group>
      <Form.Group className="col-md-4 col-lg-3 arrow">
        <label>City</label>
        <select
          disabled={!edit}
          onChange={(e) => captureChange(e)}
          type="select"
          id="city"
          name="city_id"
          value={profile.city_id}
        >
          <option value="">Select City</option>
          {cities?.map((city) => {
            return (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            );
          })}
        </select>
      </Form.Group>
      <Form.Group className="col-md-4 col-lg-3">
        <label>Zip Code</label>
        <input
          disabled={!edit}
          onChange={(e) => captureChange(e)}
          type="text"
          id="zipcode"
          name="zip_code"
          defaultValue={profile.zip_code}
        />
      </Form.Group>
    </Form>,
    <Form className="d-flex row" key={4}>
      <Form.Group className="col-md-5">
        <label>Contact No. (Primary)</label>
        <input
          disabled={!edit}
          onChange={(e) => captureChange(e)}
          type="tel"
          id="contact"
          name="phone"
          defaultValue={profile.phone}
        />
      </Form.Group>
      <Form.Group className="col-md-6 d-flex step3 align-grid-wrapper">
        <p>This is my</p>
        <div className="align-grid">
        <Form.Check
          disabled={!edit}
          type="radio"
          label="Home"
          name="phone_no_type"
          id="formHorizontalRadios1"
          value={1}
          onChange={(e) => captureChange(e)}
          checked={profile.phone_no_type === 1}
        />
        <Form.Check
          disabled={!edit}
          type="radio"
          label="Cell"
          name="phone_no_type"
          id="formHorizontalRadios2"
          value={2}
          onChange={(e) => captureChange(e)}
          checked={profile.phone_no_type === 2}
        />
        <p>number</p>
        </div>
      </Form.Group>
    </Form>,
    <Form className="row" key={5}>
      <Form.Group className="col-md-5">
        <label>Email Address</label>
        <input
          disabled={!edit}
          onChange={(e) => captureChange(e)}
          type="email"
          id="email"
          name="email"
          defaultValue={profile.email}
        />
      </Form.Group>
    </Form>,
    <Form className="row" key={6}>
      <Form.Group className="col-md-5">
        <label>Emergency Contact</label>
        <input
          disabled={!edit}
          onChange={(e) => captureChange(e)}
          type="text"
          id="emergency_contact"
          name="phone_emergency_contact_name"
          defaultValue={profile.phone_emergency_contact_name}
        />
      </Form.Group>
    </Form>,
    <Form className="row" key={7}>
      <Form.Group className="col-md-5">
        <label>Emergency Contact Number</label>
        <input
          disabled={!edit}
          onChange={(e) => captureChange(e)}
          type="tel"
          id="emergency_number"
          name="phone_emergency"
          defaultValue={profile.phone_emergency}
        />
      </Form.Group>
    </Form>,
    <Form key={8}>
      <div className="row">
        <div className="col-12">
          <label>Relationship to you</label>
        </div>
      </div>
      <div className="row step3 step8">
        <Form.Group className="col-md-3 col-lg-2">
          <Form.Check
            disabled={!edit}
            type="radio"
            label="Spouse"
            name="phone_emergency_contact_relationship"
            id="formHorizontalRadios3"
            value={'Spouse'}
            onChange={(e) => captureChange(e)}
            checked={profile.phone_emergency_contact_relationship === 'Spouse'}
          />
        </Form.Group>
        <Form.Group className="col-md-3 col-lg-2">
          <Form.Check
            disabled={!edit}
            type="radio"
            label="Son"
            name="phone_emergency_contact_relationship"
            id="formHorizontalRadios4"
            value={'Son'}
            onChange={(e) => captureChange(e)}
            checked={profile.phone_emergency_contact_relationship === 'Son'}
          />
        </Form.Group>
        <Form.Group className="col-md-3 col-lg-2">
          <Form.Check
            disabled={!edit}
            type="radio"
            label="Daughter"
            name="phone_emergency_contact_relationship"
            id="formHorizontalRadios5"
            value={'Daughter'}
            onChange={(e) => captureChange(e)}
            checked={
              profile.phone_emergency_contact_relationship === 'Daughter'
            }
          />
        </Form.Group>
        <Form.Group className="col-md-3 col-lg-2">
          <Form.Check
            disabled={!edit}
            type="radio"
            label="Other"
            name="phone_emergency_contact_relationship"
            id="formHorizontalRadios6"
            value={'Other'}
            onChange={(e) => captureChange(e)}
            defaultChecked={
              profile.phone_emergency_contact_relationship === 'Other'
            }
          />
        </Form.Group>
      </div>
      <div className="row">
        {profile.phone_emergency_contact_relationship === 'Other' && (
          <Form.Group className="col-md-5">
            <input
              disabled={!edit}
              onChange={(e) => captureChange(e)}
              type="text"
              id="contact_relationship_others"
              name="contact_relationship_others"
              defaultValue={profile.contact_relationship_others ?? ''}
            />
          </Form.Group>
        )}
      </div>
    </Form>,
    <Form key={9}>
      <div className="row">
        <div className="col-12">
          <label>
            Have you started taking Social Security benefits this year?
          </label>
        </div>
      </div>
      <div className="row step3 step8">
        <Form.Group className="col-md-2">
          <Form.Check
            disabled={!edit}
            type="radio"
            label="Yes"
            name="social_benefits"
            id="social_benefits formHorizontalRadios6"
            value={1}
            onChange={(e) => captureChange(e)}
            checked={profile.social_benefits === 1}
          />
        </Form.Group>
        <Form.Group disabled={!edit} className="col-md-2 no-class">
          <Form.Check
            type="radio"
            label="No"
            name="social_benefits"
            id="social_benefits formHorizontalRadios7"
            value={0}
            onChange={(e) => captureChange(e)}
            checked={profile.social_benefits === 0}
          />
        </Form.Group>
      </div>
    </Form>,
    <Form key={10}>
      <div className="row">
        <div className="col-12">
          <label>
            If yes, do you want your Part D premiums paid automatically from
            your benefits?
          </label>
        </div>
      </div>
      <div className="row step3 step8">
        <Form.Group className="col-md-6 col-xl-4">
          <Form.Check
            disabled={!edit}
            type="radio"
            label="Yes, Simpler that way"
            name="social_benefits_paid_automatically"
            id="formHorizontalRadios8"
            value={1}
            onChange={(e) => captureChange(e)}
            checked={profile.social_benefits_paid_automatically === 1}
          />
        </Form.Group>
        <Form.Group className="col-md-6 col-xl-4 no-class">
          <Form.Check
            disabled={!edit}
            type="radio"
            label="No, I prefer to pay it myself"
            name="social_benefits_paid_automatically"
            id="formHorizontalRadios9"
            value={0}
            onChange={(e) => captureChange(e)}
            checked={profile.social_benefits_paid_automatically === 0}
          />
        </Form.Group>
      </div>
    </Form>,
    <Form key={11} className="prescriptions-details">
      <div className="row">
        <div className="col-12">
          <label>
            An additional service we provide is finding the proper Medicare Part
            D prescription plan
          </label>
        </div>
      </div>
      <div className="row">
        <Form.Group className="col-12">
          <Form.Check
            disabled={!edit}
            type="radio"
            label="Yes! I want peace of mind and possibly financial savings! Let's check my medications"
            name="check_my_medications"
            id="formHorizontalRadios10 yes"
            onClick={() => setDialog2(true)}
            readOnly
            checked={profile.check_my_medications === 1}
          />
        </Form.Group>
        <Form.Group className="col-12 no-class">
          <Form.Check
            disabled={!edit}
            type="radio"
            label="No, I’m just hoping things will be ok (even though experts advise otherwise). No
                        evaluation this year."
            name="check_my_medications"
            id="formHorizontalRadios11 No"
            value={0}
            readOnly
            onChange={(e) => captureChange(e)}
            checked={profile.check_my_medications === 0}
          />
        </Form.Group>
      </div>
      <Modal show={dialog2}>
        <Modal.Body>
          <Form className="popup-form">
            <i className="cross">
              <img
                src={imageCross}
                alt="cross"
                onClick={() => setDialog2(false)}
              />
            </i>
            <h5>Are you sure?</h5>
            <Form.Group>
              <Form.Check
                disabled={!edit}
                type="radio"
                label="Yes, I will take the risk"
                name="check_my_medications"
                id="formHorizontalRadios10 yes"
                value={1}
                onChange={(e) => captureChange(e)}
                checked={profile.check_my_medications === 1}
              />
              {profile.check_my_medications === 1 && (
                <p>
                  "Thanks for updating your info, let us know if you change your
                  mind. Open Enrollment ends December 7!"
                </p>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Check
                disabled={!edit}
                className=" no-class"
                type="radio"
                label="No, I changed my mind"
                name="check_my_medications"
                id="formHorizontalRadios11 No"
                value={-1}
                onChange={(e) => captureChange(e)}
                checked={profile.check_my_medications === -1}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </Form>,
    <div key={12}>
      <Button
        key={13}
        className="btn btn-primary"
        onClick={() => (edit ? apply_changes() : setEdit(true))}
      >
        {edit ? 'Save' : 'Edit'}
      </Button>
      {isLoading && <CustomSpinner animation={'border'} />}
    </div>,
  ];

  return (
    <div className="main-wrapper">
      <div className="row heading-wrapper">
        <div className="col-8">
          <h2 className="main-heading">Patient Profile Review</h2>
        </div>
      </div>
      <div className="row">
        <div className="section-top col-12">
          <Toast
            onClose={() => setError(false)}
            show={error}
            delay={10000}
            autohide
            style={{
              position: 'fixed',
              top: 20,
              right: 20,
              minWidth: '300px',
            }}
          >
            <Toast.Header>
              <strong className="mr-auto">Missing Fields</strong>
              {/* <small>messages</small> */}
            </Toast.Header>
            {missingFields.map((message, index) => {
              return <Toast.Body key={index}>{message}</Toast.Body>;
            })}
          </Toast>
          {step1Array}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Profile);
